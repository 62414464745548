export interface UserModel {
  id?: String;
  phone_number: String;
  email: String;
  status?: String;
  on_duty: Boolean;
  isVerified?: Boolean;
  fcm_token?: String;
  name: {
    first_name: String;
    last_name?: String;
  };
  employee_code: String;
  role: Role; // One of the value admin, rider, supervisor
  createdAt?: Date;
  updatedAt?: Date;
  supervisor?: String;
  supervisor_name?: String; // If Rider then( ID of designated supervisor)
  account_status?: AccountStatus;
  documents?: string;
  docData?: any;
  reason?: String | null;
  bank_name?: String;
  bank_branch?: String;
  bank_account_number?: String;
  all_documents?: any;
}

export enum Role {
  rider = "rider",
  supervisor = "supervisor",
  admin = "admin",
}

export enum AccountStatus {
  applied = "applied",
  verified = "verified",
  rejected = "rejected",
  re_apply = "re_apply",
  blocked = "blocked",
  pending = "pending",
}
