import firebase from "firebase/app";
import STATICS from "../../env/static";
require("firebase/functions");
require("firebase/firestore");
require("firebase/auth");

require("firebase/storage");
// import { getStorage } from 'firebase/storage';
// import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyBPBmQUlPSI9zFjxkbsKivr_HNlyH0o5pM",
//   authDomain: "curebayapp.firebaseapp.com",
//   projectId: "curebayapp",
//   storageBucket: "curebayapp.appspot.com",
//   messagingSenderId: "661695160924",
//   appId: "1:661695160924:web:f3d9ebbd357d9e00333c99",
//   measurementId: "G-FWKL6SLDDE",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyDqfEctsU4Q5rjTlQmzasb70D9GpZbBWmI",
//   authDomain: "accio-logistics-test.firebaseapp.com",
//   projectId: "accio-logistics-test",
//   storageBucket: "accio-logistics-test.appspot.com",
//   messagingSenderId: "872021869065",
//   appId: "1:872021869065:web:77386800e8ab3911e6aed8",
//   measurementId: "G-WLMVZ6WG44",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDxMcu-yEAogSwR1OowxE-v7ICCPMACiIw",
  authDomain: "accio-wfm.firebaseapp.com",
  projectId: "accio-wfm",
  storageBucket: "accio-wfm.appspot.com",
  messagingSenderId: "911748491391",
  appId: "1:911748491391:web:b840cf570a7c17bef5b74a",
  measurementId: "G-5DKFYJZ166"
};

// NOTE: To run firebase function on local machine check link -> https://firebase.google.com/docs/functions/local-emulator
export const firebaseApp = firebase.initializeApp(firebaseConfig);

// export const storage = getStorage(firebaseApp);
export const storage = firebase.storage();
// if (
//   window.location.hostname === "localhost" ||
//   window.location.hostname === "127.0.0.1" ||
//   window.location.hostname === ""
// ) {
//   firebaseApp.functions().useEmulator("localhost", 5001);
// }
export const db = firebase.firestore();
export const functions = firebaseApp.functions();
// export const getFunction =
